import type Bucket from '~/types/bucket'

export const sortBuckets = (buckets: Bucket[]) =>
  buckets.toSorted((a, b) => {
    if (a.is_default_list) return -1
    if (b.is_default_list) return 1
    if (b.is_default_list) return 1
    if (a.is_groover_made && b.is_groover_made) return a.name < b.name ? -1 : 1
    if (a.is_groover_made) return -1
    if (b.is_groover_made) return 1

    return (
      new Date(b.datetime_updated).getTime() -
      new Date(a.datetime_updated).getTime()
    )
  })
